<template>
  <v-container>
    <router-view  style="max-width: 960px; margin: auto;"></router-view>
  </v-container>
</template>

<script>

export default {
  components: {
    // TopMenu: () => import('../..components/app/TopMenu.vue'),
  },
  created() {},
};

</script>
